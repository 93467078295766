import type { SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import type { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { getPlanPrice } from '@wix/pricing-plans-utils';
import { usePrice } from '../../../../../hooks';

export function useOrderSummary(plan: PublicPlan, spannedPrices: SpannedPrice[]) {
  const planPrice = getPlanPrice(plan);
  const price = spannedPrices[0]?.price;
  const { fullPrice: total } = usePrice(price?.total ?? planPrice.value, planPrice.currency);
  const { fullPrice: subtotal } = usePrice(price?.subtotal, planPrice.currency);
  const { fullPrice: taxAmount } = usePrice(price?.tax?.amount, planPrice.currency);
  const amount = price?.coupon?.amount;
  const { fullPrice: couponAmount } = usePrice(amount ? `-${amount}` : undefined, planPrice.currency);

  return { total, subtotal, taxAmount, couponAmount };
}
