import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEmpty } from 'lodash';
import { Text } from 'wix-ui-tpa/cssVars';
import { PublicPlan } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { checkoutStage } from '@wix/bi-logger-membership/v2';
import { FormViewer } from '@wix/form-viewer/widget';
import type { FormViewerHandle, FormValues, FormError } from '@wix/form-viewer/widget';
import { useErrorMonitor, useTranslation, useBi } from '@wix/yoshi-flow-editor';
import { useLogger } from '../../../../../hooks/useLogger';
import { CheckoutStyledButton } from '../CheckoutStyledButton';
import { FormPreview } from '../FormPreview';
import { StepSection, Action } from '../StepSection';
import { StepAPI, StepProps } from '../types';
import { ADDITIONAL_INFO_STEP_CONTINUE_BUTTON, ADDITIONAL_INFO_STEP_SECTION } from './AdditionalInfoStep.hooks';
import { classes } from './AdditionalInfoStep.st.css';

interface AdditionalInfoStepProps extends StepProps {
  plan: PublicPlan;
  onChange: (formValues: FormValues) => void;
  guestCheckoutEnabled: boolean;
}

export const AdditionalInfoStep: React.VFC<AdditionalInfoStepProps> = ({
  position,
  onApiSet,
  onCompleted,
  setActive,
  isActiveStep,
  isLastStep,
  isNarrow,
  state,
  plan,
  onChange,
  guestCheckoutEnabled,
}) => {
  const logger = useLogger();

  logger.log('AdditionalInfoStep()', {
    position,
    isActiveStep,
    isLastStep,
    isNarrow,
    state,
    plan,
    guestCheckoutEnabled,
  });

  const { t, i18n } = useTranslation();
  const errorMonitor = useErrorMonitor();
  const biLogger = useBi();

  const formViewer = useRef<FormViewerHandle>(null);
  const isFormValid = useRef<boolean>(false);
  const [isFormValidating, setIsFormValidating] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>({});
  const [formErrors, setFormErrors] = useState<FormError[]>([]);
  const [hasError, setHasError] = useState<boolean>(false);

  useEffect(() => {
    biLogger.report(
      checkoutStage({
        guestCheckout: guestCheckoutEnabled,
        planGuid: plan.id,
        stage: 'custom_checkout_component_loaded',
      }),
    );
  }, []);

  const handleFormChange = useCallback(
    (values: FormValues) => {
      logger.log('handleFormChange', values);
      setFormValues(values);
      isFormValid.current = false;
      onChange(values);
    },
    [onChange],
  );

  const handleFormValidate = useCallback((errors: FormError[]) => {
    logger.log('handleFormValidate', errors);
    setFormErrors(errors);
  }, []);

  const handleError = useCallback(
    (error: Error) => {
      logger.log('handleError', error);
      errorMonitor.captureException(error);
      setHasError(true);
    },
    [errorMonitor, setHasError],
  );

  const handleClick = useCallback(async () => {
    logger.log('handleClick');
    biLogger.report(
      checkoutStage({
        guestCheckout: guestCheckoutEnabled,
        planGuid: plan.id,
        stage: 'custom_checkout_continue_click',
      }),
    );

    if (!formViewer.current) {
      logger.log('handleClick - no formViewer');
      return;
    }

    setIsFormValidating(true);
    logger.log('handleClick - setIsFormValidating');
    const isValid = await formViewer.current.validate();
    logger.log('handleClick - isValid', isValid);
    isFormValid.current = isValid;
    setIsFormValidating(false);

    if (!isValid) {
      return;
    }

    logger.log('handleClick - onCompleted');
    onCompleted();
  }, [biLogger, guestCheckoutEnabled, plan, onCompleted]);

  const api: StepAPI = useMemo(
    () => ({
      execute: () => handleClick(),
      isValid: () => isFormValid.current,
    }),
    [handleClick, isFormValid],
  );

  useEffect(() => {
    onApiSet(api);
  }, [api]);

  return (
    <StepSection
      dataHook={ADDITIONAL_INFO_STEP_SECTION}
      position={position}
      title={t('payment.checkout-steps.additional-info.title')}
      state={state}
      action={
        state === 'completed' ? (
          <Action onClick={setActive}>{t('payment.checkout-steps.additional-info.edit-button')}</Action>
        ) : undefined
      }
    >
      {state === 'active' ? (
        hasError ? (
          <div className={classes.root}>
            <div className={classes.emptyState}>
              <div className={classes.emptyStateContent}>
                <Text className={classes.emptyStateTitle} tagName="h3">
                  {t('payment.checkout-steps.additional-info.empty-state-title')}
                </Text>
                <Text className={classes.emptyStateText} tagName="p">
                  {t('payment.checkout-steps.additional-info.empty-state-text')}
                </Text>
              </div>
            </div>
            {!isLastStep || isNarrow ? (
              <CheckoutStyledButton
                className={classes.button}
                onClick={onCompleted}
                label={t('payment.checkout-steps.additional-info.skip-button')}
              />
            ) : null}
          </div>
        ) : (
          <div className={classes.root}>
            <FormViewer
              i18n={i18n}
              bi={biLogger}
              ref={formViewer}
              formId={plan.formId!}
              values={formValues}
              errors={formErrors}
              onChange={handleFormChange}
              onValidate={handleFormValidate}
              onError={handleError}
            />
            {!isLastStep || isNarrow ? (
              <CheckoutStyledButton
                dataHook={ADDITIONAL_INFO_STEP_CONTINUE_BUTTON}
                className={classes.button}
                onClick={handleClick}
                disabled={isFormValidating}
                label={t('payment.checkout-steps.additional-info.continue-button')}
              />
            ) : null}
          </div>
        )
      ) : state === 'completed' && !isEmpty(formValues) ? (
        <div className={classes.root}>
          <FormPreview formId={plan.formId!} formValues={formValues} />
        </div>
      ) : null}
    </StepSection>
  );
};
