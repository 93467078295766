import merge from 'lodash/merge';
import { Benefit, BenefitType } from '@wix/ambassador-pricing-plans-v2-benefit/types';
import { Order, SpannedPrice } from '@wix/ambassador-pricing-plans-v2-order/types';
import { PublicPlan, PeriodUnit } from '@wix/ambassador-pricing-plans-v2-plan/types';
import { isRecurringPlan, isUnlimitedPlan } from '@wix/pricing-plans-utils';
import { TFunction } from '@wix/yoshi-flow-editor';
import { EMPTY_PLAN } from './constants';

type PeriodStringType = 'Singular' | 'Plural' | 'Frequency';

const unitMap = {
  [PeriodUnit.DAY]: {
    Singular: 'day',
    Plural: 'days',
    Frequency: 'daily',
  },
  [PeriodUnit.WEEK]: {
    Singular: 'week',
    Plural: 'weeks',
    Frequency: 'weekly',
  },
  [PeriodUnit.MONTH]: {
    Singular: 'month',
    Plural: 'months',
    Frequency: 'monthly',
  },
  [PeriodUnit.YEAR]: {
    Singular: 'year',
    Plural: 'years',
    Frequency: 'yearly',
  },
} as const;

class BaseCheckoutSummaryPresenter {
  constructor(
    protected plan: PublicPlan,
    protected prices: SpannedPrice[],
    protected benefits: Benefit[],
    protected locale: string,
    protected t: TFunction,
    protected order?: Order,
  ) {
    this.plan = merge({}, EMPTY_PLAN, plan);
  }

  protected parseAndFormatAmount(amount?: string, currency?: string) {
    if (amount) {
      if (currency) {
        return parseFloat(amount).toLocaleString(this.locale, {
          minimumFractionDigits: typeof amount === 'string' && amount.includes('.') ? undefined : 0,
          style: 'currency',
          currency,
        });
      }
      return amount;
    }
  }

  protected getTax() {
    return this.prices[0]?.price?.tax ?? {};
  }

  protected getCycleLength() {
    return this.plan.pricing?.subscription?.cycleDuration?.count ?? undefined;
  }

  protected hasCustomCycles() {
    const length = this.getCycleLength();
    return !!length && length !== 1;
  }

  protected getPeriodUnit() {
    return this.plan.pricing?.subscription?.cycleDuration?.unit ?? this.plan.pricing?.singlePaymentForDuration?.unit;
  }

  protected getPlanDuration() {
    if (this.isRecurring()) {
      return (this.getCycleLength() ?? 1) * (this.plan.pricing?.subscription?.cycleCount ?? 1);
    }

    return this.plan.pricing?.singlePaymentForDuration?.count ?? undefined;
  }

  protected getFreeTrial() {
    return this.plan.pricing?.freeTrialDays;
  }

  protected hasDiscountedCycles() {
    return this.prices.length > 1;
  }

  protected discountedCyclesCount() {
    if (this.hasDiscountedCycles()) {
      return this.prices[0].duration?.numberOfCycles ?? undefined;
    }

    return undefined;
  }

  protected getFormatedActualPrice() {
    if (this.hasDiscountedCycles()) {
      const price = this.prices[1].price;
      return this.parseAndFormatAmount(price?.total, price?.currency);
    }
    return undefined;
  }

  protected getFormatedDiscountedPrice() {
    if (this.prices.length > 0) {
      const price = this.prices[0].price;
      return this.parseAndFormatAmount(price?.total, price?.currency);
    }

    return undefined;
  }

  periodString(stringType: PeriodStringType) {
    const unit = this.getPeriodUnit();

    if (!unit || unit === PeriodUnit.UNDEFINED) {
      return '';
    }

    return unitMap[unit][stringType];
  }

  getPlanName() {
    return this.plan.name;
  }

  hasSessions() {
    return this.benefits ? this.benefits.length > 0 : false;
  }

  isRecurring() {
    return isRecurringPlan(this.plan);
  }

  hasCoupon() {
    return Boolean(this.prices[0]?.price?.coupon);
  }

  getTaxName() {
    return this.getTax().name;
  }

  getTaxRate() {
    return this.getTax().rate;
  }

  showFreeTrial() {
    return Boolean(this.plan.pricing?.freeTrialDays);
  }

  isFreeTrialUnavailable() {
    return this.order && !this.order.freeTrialDays;
  }
}

export class CheckoutSummaryPresenter extends BaseCheckoutSummaryPresenter {
  getSessionCount() {
    if (this.hasSessions()) {
      const benefit = this.benefits[0];
      if (benefit.benefitType === BenefitType.UNLIMITED) {
        return this.t('payment.checkout-summary.sessions-unlimited');
      } else {
        return benefit.creditAmount;
      }
    }
  }

  getDuration() {
    if (isUnlimitedPlan(this.plan)) {
      return this.t('payment.checkout-summary.duration-unlimited');
    }

    const duration = this.getPlanDuration();

    if (duration === 1) {
      return this.t(`payment.checkout-summary.duration-1-${this.periodString('Singular')}`);
    } else {
      return this.t(`payment.checkout-summary.duration-n-${this.periodString('Plural')}`, { amount: duration });
    }
  }

  getFreeTrialDays() {
    const days = this.getFreeTrial();

    if (!days) {
      return this.t('payment.checkout-summary.trial-unavailable');
    }
    return days === 1
      ? this.t('payment.checkout-summary.trial.duration-1-day')
      : this.t('payment.checkout-summary.trial.duration-n-days', { amount: days });
  }

  getPaymentPeriod() {
    if (!this.isRecurring()) {
      return null;
    }

    const discountedCycles = this.discountedCyclesCount();

    if (discountedCycles) {
      if (this.hasCustomCycles()) {
        return null;
      }

      return this.t('payment.checkout-summary.payment-period-limited-' + this.periodString('Singular'), {
        count: discountedCycles,
      });
    }

    return this.t('payment.checkout-summary.payment-period-' + this.periodString('Singular'), {
      count: this.getCycleLength() ?? 1,
    });
  }

  private getPaymentFrequency(): string | null {
    return this.t(`payment.checkout-summary.payment-disclaimer.cycle-${this.periodString('Frequency')}-plural`, {
      count: this.getCycleLength(),
    });
  }

  private getDisclaimerKey() {
    if (this.hasDiscountedCycles()) {
      if (this.showFreeTrial() && !this.isFreeTrialUnavailable()) {
        if (isUnlimitedPlan(this.plan)) {
          if (this.hasCustomCycles()) {
            return 'payment.checkout-summary.payment-disclaimer.free-trial-unlimited-with-discount-with-custom-cycles';
          } else {
            return `payment.checkout-summary.payment-disclaimer.free-trial-unlimited-with-discount-${this.periodString(
              'Singular',
            )}`;
          }
        } else {
          if (this.hasCustomCycles()) {
            return 'payment.checkout-summary.payment-disclaimer.free-trial-limited-with-discount-with-custom-cycles';
          } else {
            return `payment.checkout-summary.payment-disclaimer.free-trial-limited-with-discount-${this.periodString(
              'Singular',
            )}`;
          }
        }
      } else {
        if (isUnlimitedPlan(this.plan)) {
          if (this.hasCustomCycles()) {
            return 'payment.checkout-summary.payment-disclaimer.unlimited-with-discount-with-custom-cycles';
          } else {
            return `payment.checkout-summary.payment-disclaimer.unlimited-with-discount-${this.periodString(
              'Singular',
            )}`;
          }
        } else {
          if (this.hasCustomCycles()) {
            return 'payment.checkout-summary.payment-disclaimer.limited-with-discount-with-custom-cycles';
          } else {
            return `payment.checkout-summary.payment-disclaimer.limited-with-discount-${this.periodString('Singular')}`;
          }
        }
      }
    } else {
      if (this.showFreeTrial() && !this.isFreeTrialUnavailable()) {
        if (isUnlimitedPlan(this.plan)) {
          return 'payment.checkout-summary.payment-disclaimer.free-trial-unlimited';
        } else {
          return 'payment.checkout-summary.payment-disclaimer.free-trial-limited';
        }
      } else {
        if (isUnlimitedPlan(this.plan)) {
          return 'payment.checkout-summary.payment-disclaimer.unlimited';
        } else {
          return 'payment.checkout-summary.payment-disclaimer.limited';
        }
      }
    }
  }

  private getDisclaimerDuration() {
    const count = this.getPlanDuration();
    return this.t(`payment.checkout-summary.payment-disclaimer.duration-${this.periodString('Singular')}`, { count });
  }

  getPaymentDisclaimer() {
    if (!this.isRecurring()) {
      return null;
    }

    return this.t(this.getDisclaimerKey(), {
      cycle: this.getPaymentFrequency(),
      duration: this.getDisclaimerDuration(),
      count: this.discountedCyclesCount(),
      amount: this.getFormatedActualPrice(),
      discountedPrice: this.getFormatedDiscountedPrice(),
    });
  }
}
