import React, { useEffect, useMemo } from 'react';
import { Text } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useBreakpoints } from '../../../../../hooks/useBreakpoints';
import { CheckoutProps } from '../../../../../types/common';
import { CheckoutStyledButton } from '../CheckoutStyledButton';
import { PurchaseLimitNotification } from '../PurchaseLimitNotification';
import { Action, StepSection } from '../StepSection';
import { StepAPI, StepProps } from '../types';
import { classes, st } from './LoginStep.st.css';

interface LoginStepProps extends StepProps {
  user: CheckoutProps['user'];
  userEmail: CheckoutProps['userEmail'];
  onLoginClick(): void;
  onSignupClick(): void;
  onLogoutClick(): void;
  purchaseLimitExceeded: boolean;
}

export const LoginStep: React.VFC<LoginStepProps> = ({
  position,
  onApiSet,
  onCompleted,
  user,
  userEmail,
  onLoginClick,
  onSignupClick,
  onLogoutClick,
  isActiveStep,
  state,
  purchaseLimitExceeded,
}) => {
  const { t } = useTranslation();
  const { isExtraNarrow } = useBreakpoints();

  const api: StepAPI = useMemo(
    () => ({
      execute: async () => onSignupClick(),
      isValid: () => user.loggedIn,
    }),
    [user, onSignupClick],
  );

  useEffect(() => {
    onApiSet(api);
  }, [api]);

  useEffect(() => {
    if (isActiveStep && user.loggedIn && !purchaseLimitExceeded) {
      onCompleted();
    }
  }, [isActiveStep, user.loggedIn, onCompleted, purchaseLimitExceeded]);

  return (
    <StepSection
      dataHook="payment-login-section"
      position={position}
      title={t('payment.checkout-steps.login.title')}
      state={user.loggedIn && !purchaseLimitExceeded ? 'completed' : state}
      action={
        user.loggedIn ? (
          <Action dataHook="member-section-log-out" onClick={() => onLogoutClick()}>
            {t('payment.checkout-steps.sign-up.logout-button')}
          </Action>
        ) : undefined
      }
    >
      <div className={st(classes.root, { extraNarrow: isExtraNarrow })}>
        {user.loggedIn ? (
          <>
            <Text className={classes.memberInfo} tagName="p" data-hook="member-section-info">
              {t('payment.checkout-steps.sign-up.body', { identity: userEmail })}
            </Text>
            {purchaseLimitExceeded && <PurchaseLimitNotification />}
          </>
        ) : (
          <>
            <Text className={classes.disclaimer} tagName="p">
              {t('payment.checkout-steps.login.disclaimer')}
            </Text>
            <div className={classes.actionContainer}>
              <CheckoutStyledButton
                className={classes.button}
                label={t('payment.checkout-steps.login.sign-up-button')}
                onClick={() => onSignupClick()}
                dataHook="login-section-sign-up"
              />
              <CheckoutStyledButton
                className={classes.button}
                label={t('payment.checkout-steps.login.log-in-button')}
                onClick={() => onLoginClick()}
                dataHook="login-section-log-in"
                secondary
              />
            </div>
          </>
        )}
      </div>
    </StepSection>
  );
};
